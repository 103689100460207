import { Link } from 'gatsby'
import React, { FC } from 'react'
import ico_user from '../images/ico_user.png'

type Props = {
  title: string
  link: string
  author: string
  date: string
  time: string
  category: string
}

export const PostHeader: FC<Props> = ({ title, link, author, date, time, category }) => {
  return (
    <div className="topTitle">
      <h1><Link to={link}>{title}</Link></h1>
      <i>Napsal <img src={ico_user} alt="user" /><a href="/" title={`Posts by ${author}`} rel="author" onClick={e => e.preventDefault()}>{author}</a>, dne {date}, {time}, kategorie <a href="/" title={`View all posts in ${category}`} rel="category" onClick={e => e.preventDefault()}>{category}</a></i>
    </div>
  )
}