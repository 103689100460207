import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?p=211

const EventKdeJeGm: FC = () => {
  return (
    <PageTemplate>
      <PostHeader
        title="KDE JE GM?"
        link="/event_kde_je_gm"
        author="Algy"
        date="18.03.2014"
        time="13:23"
        category="Eventy"
      />
      <p><strong>KDE JE GM?</strong></p>
      <p>Spočívá v tom, že hráči hledají GM podle popisu, který jim GM poskytuje o poloze, kde se schoval. GM je na uvedeném místa viditelný a lze ho cílovat. Odměnu tři hráči, kteří GM naleznou jako první.</p>
      <div className="cleared"></div>
      <span className="linkpages"></span><div className="cleared"></div>

      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default EventKdeJeGm

export { Head } from '../components/defaultHtmlHead'